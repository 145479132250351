import React from "react"
import { Helmet } from "react-helmet"
import KdpHeader from "../blocs/Header/Header"
import KdpFooter from "../blocs/Footer/Footer"
import KdpContact from "../blocs/Contact"
import KdpAriane from "../blocs/Ariane"
//<script src='/jCaptcha.js' type="text/javascript" />
const ariane = [
  { name: 'Nous contacter', href: '', current: true },
]

export default function Contact() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Contacter CEOS Avocats</title>
        <meta name="description" content="Contacter CEOS Avocats ou postuler à une offre d’emploi" />
        
      </Helmet>
      <header>
        <KdpHeader />
      </header>
      <main>
        <KdpAriane 
          filariane={ariane}
        />
        <KdpContact />
      </main>
      <footer>
        <KdpFooter />
      </footer>
      
    </div>
  );
}

